import validate from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.24.0_rollup@4.39.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.5/node_modules/nuxt/dist/pages/runtime/validate.js";
import cms_45global from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/cms/middleware/cms.global.ts";
import auth_45token_45global from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/middleware/auth-token.global.ts";
import backend_45token_45global from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/middleware/backend-token.global.ts";
import affiliate_45and_45referrer_45global from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/base/middleware/affiliate-and-referrer.global.ts";
import consent_45global from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/base/middleware/consent.global.ts";
import utm_45global from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/base/middleware/utm.global.ts";
import widget_45mode_45global from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/middleware/widget-mode.global.ts";
import redirects_45global from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/apps/kinoheld/middleware/redirects.global.ts";
import manifest_45route_45rule from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.10.1_eslint@9.24.0_rollup@4.39.0_tsx@4.19.2_typescript@5.8.3_vite@6.2.5/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cms_45global,
  auth_45token_45global,
  backend_45token_45global,
  affiliate_45and_45referrer_45global,
  consent_45global,
  utm_45global,
  widget_45mode_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/middleware/admin.ts")
}