
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46pageSlug_93RGjKbvWF5FMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activatecC3QiSNz7bMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/activate.vue?macro=true";
import { default as indexOuVuWmCFgJMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/index.vue?macro=true";
import { default as _91securityKey_93ZnGMzL9funMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newlqritnWP6zMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/password/new.vue?macro=true";
import { default as watchlistpxsacTHltMMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/watchlist.vue?macro=true";
import { default as indexzY0N8cjvedMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/index.vue?macro=true";
import { default as editdPPKPJ91NTMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffle/[id]/edit.vue?macro=true";
import { default as indexmpLVlwQS0PMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffle/[id]/index.vue?macro=true";
import { default as addTqE7Zxjn1OMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffle/add.vue?macro=true";
import { default as rafflesCmE6FaQJLpMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffles.vue?macro=true";
import { default as faqpDxJG5iXa0Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as infoVAZSvD6QlJMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as _91showGroupSlug_93vRAOhS29G4Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as programcsR7V92MR6Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shopbOzf5EBcx8Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showSlug_931dBGTt7K1KMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as snackshopU8ZfLC5jXKMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/snackshop.vue?macro=true";
import { default as subscriptionCh6B8obyL8Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue?macro=true";
import { default as vouchers6FiPEuniZEMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93ocuXFXkFT6Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indexsoVEh0EjIpMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as cinemasqfiAMVPyygMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinemas.vue?macro=true";
import { default as citiesVXeWDDU40KMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cities.vue?macro=true";
import { default as index78WkeFOTlvMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/index.vue?macro=true";
import { default as _91movieSlug_93rvL9B7Ei1fMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as indexRGHv2Z6Y2RMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_93CcRiB8nIaoMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genres3nJs7eMtq4Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexmHf8WRxHWkMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/index.vue?macro=true";
import { default as _91personSlug_93NMHem6DVkSMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personsFOWjNujKQGMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/persons.vue?macro=true";
import { default as searchRHpuSTcx6TMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/search.vue?macro=true";
import { default as tabs_45testegUMNFAL8kMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/tabs-test.vue?macro=true";
import { default as vouchers15QF2J6HSuMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/cms/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/cms/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/cms/pages/[...pageSlug].vue")
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/activate.vue")
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/activate.vue")
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/activate.vue")
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/index.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/index.vue")
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/index.vue")
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber()/:securityKey()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/order/[orderNumber]/[securityKey].vue")
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber()/:securityKey()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/order/[orderNumber]/[securityKey].vue")
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber()/:securityKey()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/order/[orderNumber]/[securityKey].vue")
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/password/new.vue")
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/password/new.vue")
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/password/new.vue")
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/watchlist.vue")
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/watchlist.vue")
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/account/pages/account/watchlist.vue")
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexzY0N8cjvedMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/index.vue")
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexzY0N8cjvedMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/index.vue")
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexzY0N8cjvedMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/index.vue")
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: editdPPKPJ91NTMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffle/[id]/edit.vue")
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: editdPPKPJ91NTMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffle/[id]/edit.vue")
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: editdPPKPJ91NTMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffle/[id]/edit.vue")
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexmpLVlwQS0PMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffle/[id]/index.vue")
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: indexmpLVlwQS0PMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffle/[id]/index.vue")
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexmpLVlwQS0PMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffle/[id]/index.vue")
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addTqE7Zxjn1OMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffle/add.vue")
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addTqE7Zxjn1OMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffle/add.vue")
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addTqE7Zxjn1OMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffle/add.vue")
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesCmE6FaQJLpMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffles.vue")
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesCmE6FaQJLpMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffles.vue")
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesCmE6FaQJLpMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/builder/pages/admin/raffles.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/kino/:citySlug()/:cinemaSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue"),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "film/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "vorstellung/:showSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-snackshop___de",
    path: "snackshop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/snackshop.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___de",
    path: "abo",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue")
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug()/:cinemaSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue"),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "program",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-snackshop___en",
    path: "snackshop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/snackshop.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___en",
    path: "subscription",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "vouchers",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue")
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/es/cine/:citySlug()/:cinemaSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue"),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "informacion",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "sesiones",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "evento/:showSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-snackshop___es",
    path: "snackshop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/snackshop.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___es",
    path: "suscripcion",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue")
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "/cinema/:citySlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/index.vue")
  },
  {
    name: "cinema-citySlug___en",
    path: "/en/cinema/:citySlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/index.vue")
  },
  {
    name: "cinema-citySlug___es",
    path: "/es/cinema/:citySlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinema/[citySlug]/index.vue")
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinemas.vue")
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinemas.vue")
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cinemas.vue")
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cities.vue")
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cities.vue")
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/cities.vue")
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/index.vue")
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movie/[movieSlug].vue")
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movie/[movieSlug].vue")
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movie/[movieSlug].vue")
  },
  {
    name: _91genreSlug_93CcRiB8nIaoMeta?.name,
    path: "/filme/genre/:genreSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/genre/[genreSlug].vue"),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/genre/[genreSlug]/index.vue")
  }
]
  },
  {
    name: _91genreSlug_93CcRiB8nIaoMeta?.name,
    path: "/en/movies/genre/:genreSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/genre/[genreSlug].vue"),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/genre/[genreSlug]/index.vue")
  }
]
  },
  {
    name: _91genreSlug_93CcRiB8nIaoMeta?.name,
    path: "/es/peliculas/generos/:genreSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/genre/[genreSlug].vue"),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/genre/[genreSlug]/index.vue")
  }
]
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/genres.vue")
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/genres.vue")
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/genres.vue")
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/index.vue")
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/index.vue")
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/movies/index.vue")
  },
  {
    name: "person-personSlug___de",
    path: "/person/:personSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/person/[personSlug].vue")
  },
  {
    name: "person-personSlug___en",
    path: "/en/person/:personSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/person/[personSlug].vue")
  },
  {
    name: "person-personSlug___es",
    path: "/es/persona/:personSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/person/[personSlug].vue")
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/persons.vue")
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/persons.vue")
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/persons.vue")
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/search.vue")
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/search.vue")
  },
  {
    name: "tabs-test___de",
    path: "/tabs-test",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/tabs-test.vue")
  },
  {
    name: "tabs-test___en",
    path: "/en/tabs-test",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/tabs-test.vue")
  },
  {
    name: "tabs-test___es",
    path: "/es/tabs-test",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/tabs-test.vue")
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/vouchers.vue")
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/vouchers.vue")
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-08_15-52-48_286182d/layers/portal/pages/vouchers.vue")
  }
]